import type { MenuProps } from "antd";
import { Button, Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "kuaidi",
    label: <Link to="/web/kuaidi">快递单录入</Link>,
  },
  {
    key: "dadan",
    label: <Link to="/web/dadan">打单</Link>,
  },
];

const WebNav: React.FC = () => {
  const [current, setCurrent] = useState("kuaidi");
  const { nickname } = { nickname: "admin" };

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  return (
    <div className="container mx-auto flex">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
      <div className="ml-auto flex items-center">
        <p className="mr-4 text-lg">{nickname}</p>
        <Button type="primary" onClick={() => setCurrent("logout")}>
          退出
        </Button>
      </div>
    </div>
  );
};

export default WebNav;
