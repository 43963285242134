// src/components/WechatPayButton.tsx
import { client } from "@/api/axios.ts";
import {
  handleAxiosError,
  handleGenericError,
  myalert,
} from "@/utils/ErrorHandler";
import { Button } from "antd-mobile";
import axios from "axios";
import { debounce } from "lodash";
import React from "react";

// 创建微信预支付订单
// export const createWechatPayOrder = async (params: { taskId: number }) => {
//   const response = await client.post(`/wechat/pay/return_fee`, {
//     taskId: params.taskId,
//   });
//   return response.data;
// };

const WechatPayButton: React.FC<{ taskId: number }> = (props) => {
  const handlePay = async () => {
    try {
      // 获取微信支付参数
      const res = await client.post(`/wechat/pay/return_fee`, {
        taskId: props.taskId,
      });
      const wechatPayParams = res.data;
      // const wechatPayParams = await createWechatPayOrder(orderData);

      // 调用微信支付
      if (typeof window.WeixinJSBridge === "undefined") {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => onBridgeReady(wechatPayParams),
          false,
        );
      } else {
        onBridgeReady(wechatPayParams);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        // 处理 AxiosError
        handleAxiosError(error);
      } else {
        // 处理非 AxiosError
        handleGenericError(error);
      }
    }
  };

  const onBridgeReady = (params: {
    appId: string;
    timeStamp: string;
    nonceStr: string;
    package: string;
    paySign: string;
  }) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: params.appId, // 公众号名称，由商户传入
        timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
        nonceStr: params.nonceStr, // 随机串
        package: params.package, // 预支付交易会话标识
        signType: "MD5", // 微信签名方式
        paySign: params.paySign, // 微信签名
      },
      (res: any) => {
        console.log(4321, res);

        if (res.errMsg === "get_brand_wcpay_request:ok") {
          // 支付成功
          myalert("支付成功");
        } else if (res.errMsg === "get_brand_wcpay_request:cancel") {
          // 支付成功
          myalert("支付取消");
        } else {
          // 支付失败
          myalert(`支付失败--${res.errMsg}`);
        }
      },
    );
  };

  return (
    <Button
      block
      type="submit"
      color="primary"
      size="large"
      onClick={debounce(handlePay)}
    >
      微信支付
    </Button>
  );
};

export default WechatPayButton;
