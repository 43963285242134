import { Dialog, Toast } from "antd-mobile";
import { AxiosResponse } from "axios";

export function apiResult(
  res: AxiosResponse,
  options?: { toTaskList?: boolean; callback?: () => void },
) {
  if (res.status === 200) {
    Toast.show({
      content: "请求成功",
      position: "bottom",
    });
    if (options?.callback) {
      setTimeout(() => {
        options.callback && options.callback();
      }, 1000);
    } else if (options?.toTaskList) {
      setTimeout(() => {
        location.replace("/tasks");
      }, 1000);
    } else {
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  } else {
    Dialog.show({
      content: (
        <div>
          <h1>请求失败</h1>
          <p>{res.statusText}</p>
          <pre>{JSON.stringify(res.data, null, 2)}</pre>
        </div>
      ),
      actions: [
        {
          key: "ok",
          text: "请重试",
        },
      ],
    });
  }
}
