import { List } from "antd-mobile";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  return (
    <div>
      <List header="主页">
        <List.Item
          description="实名认证并注册"
          onClick={() => {
            navigate("/register");
          }}
          extra="未注册"
        >
          招募志愿者
        </List.Item>
        <List.Item
          disabled={false}
          onClick={() => {
            navigate("/add-task");
          }}
        >
          我要测评
        </List.Item>
      </List>
    </div>
  );
}
