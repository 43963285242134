import { uploadImage } from "./received";
import { client } from "@/api/axios.ts";
import FinishIcon from "@/components/FinishIcon.tsx";
import FinishInfoBar from "@/components/FinishInfoBar.tsx";
import TodoIcon from "@/components/TodoIcon.tsx";
import { API_URL } from "@/config";
import {
  IReturnInfo,
  ITaskDetail,
  statusOrder,
} from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import {
  Button,
  Form,
  ImageUploader,
  ImageUploadItem,
  Input,
  TextArea,
} from "antd-mobile";

// 已完成测评，已上传测试结果，用户使用测试结果进行退货后，上传退货信息，测评中心进行退货
export default function Evaluated({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "evaluated",
  );
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return (
      <>
        <FinishInfoBar label={"志愿者已上传退货信息"} />
      </>
    );
  }
  return (
    <>
      <Form layout="horizontal">
        <Form.Header>
          <FinishIcon label={"测评结果已上传"} />
        </Form.Header>
        <Form.Item name="" label="测评结果">
          <Resources data={data} />
        </Form.Item>
        <Form.Item name="" label="测评结论">
          {data.test_results.map((i) => (
            <p>{i}</p>
          ))}
        </Form.Item>
      </Form>
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton />}
      >
        <Form.Header>
          <TodoIcon label={"等待志愿者上传退货信息"} />
        </Form.Header>
        <Form.Item
          name="name"
          label="收货人"
          rules={[{ required: true, message: "收货人" }]}
        >
          <Input onChange={console.log} placeholder="请输入收货人" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="收货电话"
          rules={[{ required: true, message: "收货电话" }]}
        >
          <Input onChange={console.log} placeholder="请输入收货电话" />
        </Form.Item>
        <Form.Item
          name="address"
          label="收货地址"
          rules={[{ required: true, message: "收货地址" }]}
        >
          <TextArea
            placeholder="请输入收货地址"
            maxLength={100}
            rows={2}
            showCount
          />
        </Form.Item>
      </Form>
    </>
  );
}

function Resources({ data }: { data: ITaskDetail }) {
  return (
    <div>
      <ImageUploader
        value={data.task_media
          .filter((i) => i.file_type !== "video" && i.for_what !== "return_fee")
          .map(uploadImage)}
        disableUpload={true}
        deletable={false}
        showUpload={false}
        upload={function (file: File): Promise<ImageUploadItem> {
          console.log(file);
          return Promise.resolve({} as ImageUploadItem);
        }}
      />
      <div className="mt-2 flex flex-wrap gap-2">
        {data.task_media
          .filter((i) => i.file_type === "video")
          .map((i) => ({ url: API_URL + "/" + i.file_url }))
          .map((i, index) => (
            <video
              src={i.url}
              key={index}
              controls
              className="mb-1 w-full"
            ></video>
          ))}
      </div>
    </div>
  );
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      上传退货信息
    </Button>
  );
}

function onFinish(data: ITaskDetail) {
  return function (values: IReturnInfo) {
    // 上传返回退货，状态将变为已上传
    client.patch(`/tasks/${data.id}/returned`, values).then((res) => {
      apiResult(res);
    });
  };
}
