import { ITaskDetail } from "./task-detail";
import { client } from "@/api/axios";
import { List, Button } from "antd";
import { useEffect, useState } from "react";

export default function WebDaDan() {
  const [data, setData] = useState<ITaskDetail[]>([]);
  useEffect(() => {
    client.get("/tasks?status=returned,shipped").then((res) => {
      setData(res.data);
    });
  }, []);
  console.log(data);

  const [inputValue, setInputValue] = useState<string>("");
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(-1);

  return (
    <div className="container mx-auto">
      <div className="flex">
        <div className="grow">
          <List
            header={<div className="font-bold">等待退货</div>}
            bordered
            dataSource={data.filter((i) => i.status === "returned")}
            renderItem={(item, index) => (
              <List.Item>
                <div className="flex w-full">
                  <p className="flex-grow">{item.title}</p>
                  <div className="flex gap-2">
                    <Button
                      type={start === index ? "primary" : "default"}
                      size="small"
                      onClick={() => {
                        setStart(index);
                      }}
                    >
                      开始
                    </Button>
                    <Button
                      size="small"
                      type={end === index ? "primary" : "default"}
                      onClick={() => {
                        // 从start 到end 的内容用回车拼接
                        const content = data
                          .slice(start, index + 1)
                          .map((i) => i.inner_code)
                          .join("\n");
                        console.log(content);

                        setInputValue(content);
                        setEnd(index);
                      }}
                    >
                      结束
                    </Button>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <List
            className="mt-5"
            header={<div className="font-bold">已退货</div>}
            bordered
            dataSource={data.filter((i) => i.status === "shipped")}
            renderItem={(item, index) => (
              <List.Item>
                <div className="flex w-full">
                  <p className="flex-grow">{item.title}</p>
                  <div className="flex gap-2"></div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className="ml-5 w-64 rounded-md border p-2">
          <h1 className="mb-2 font-bold">内部编号</h1>
          <div className="w-full border p-2">
            <p id="text" className="whitespace-pre-line">
              {inputValue}
            </p>
          </div>
          <div className="mt-2 flex justify-end gap-2">
            <Button
              type="primary"
              onClick={() => {
                // 选中text中的文字，并复制
                const paragraph = document.getElementById(
                  "text",
                ) as HTMLParagraphElement;
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(paragraph);
                selection.removeAllRanges();
                selection.addRange(range);
                document.execCommand("copy");
                try {
                  window.navigator.clipboard.writeText(inputValue);
                } catch (error) {}
              }}
            >
              复制
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const inner_codes = inputValue.split("\n");
                client
                  .patch("/tasks", {
                    inner_codes,
                    status: "shipped", // 改为已寄出状态
                  })
                  .then(() => {
                    client.get("/tasks?status=returned,shipped").then((res) => {
                      setData(res.data);
                    });
                  });
              }}
            >
              已打单
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
