import getUser from "@/hooks/getUser";
import { IUser } from "@/router/task-detail";
import { createContext, useReducer } from "react";

// 定义 action 类型
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export interface State {
  isAuthenticated: boolean;
  user: IUser;
}

// 定义 action 类型
export interface Action {
  type: typeof LOGIN | typeof LOGOUT;
}

// 定义 reducer 函数
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case LOGIN:
      return { isAuthenticated: true, user: getUser() };
    case LOGOUT:
      return { isAuthenticated: false, user: {} as IUser };
    default:
      return state;
  }
};
const user = getUser();
// 创建初始状态
const initialState: State = {
  isAuthenticated: user.id !== undefined,
  user: user || {},
};

// 创建 Context
export const AuthContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AuthProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
