import { client } from "@/api/axios.ts";
import FinishInfoBar from "@/components/FinishInfoBar.tsx";
import TodoIcon from "@/components/TodoIcon.tsx";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { Button, Form } from "antd-mobile";

// 已运费退还
export default function ReturnedFreight({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "returned_freight",
  );
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return (
      <>
        <FinishInfoBar label={"金币已发放"} />
        <FinishInfoBar label={"任务已结束"} />
      </>
    );
  }

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton />}
      >
        <Form.Header>
          <TodoIcon label={"发放金币"} />
        </Form.Header>
        <Form.Item name="reward_coins" label="金币数量">
          <p>
            <span>{data.reward_coins}</span>
          </p>
        </Form.Item>
      </Form>
    </>
  );
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      发放奖励
    </Button>
  );
}

// 下一步
function onFinish(data: ITaskDetail) {
  return function () {
    client.patch(`/tasks/${data.id}/finished`).then((res) => {
      apiResult(res);
    });
  };
}
