import FinishInfoBar from "../FinishInfoBar";
import TodoInfoBar from "../TodoInfoBar";
import { client } from "@/api/axios.ts";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { Button, Form, Input } from "antd-mobile";

export default function Assigned({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "assigned",
  );
  if (statusIndex < receivedIndex) return null;
  // 已完成当前状态
  if (statusIndex > receivedIndex) {
    return (
      <>
        <FinishInfoBar label={"志愿者已上传快递单号"} />
      </>
    );
  }
  // 待办状态
  return (
    <>
      <TodoInfoBar label="等待志愿者上传快递单号" />
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton />}
      >
        <Form.Item
          name="kuaidi_number"
          label="快递单号"
          rules={[{ required: true, message: "快递单号" }]}
        >
          <Input placeholder="请输入快递单号" />
        </Form.Item>
      </Form>
    </>
  );
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      提交
    </Button>
  );
}

function onFinish(data: ITaskDetail) {
  return function (result: { kuaidi_number: string }) {
    client
      .patch(`/tasks/${data.id}/kuaidi_number`, {
        kuaidi_number: result.kuaidi_number,
      })
      .then((res) => {
        apiResult(res);
      });
  };
}
