import { Toast } from "antd-mobile";
import axios, { AxiosError } from "axios";

export const cacheAxios = (error: AxiosError<ErrorResData>) => {
  if (axios.isAxiosError(error)) {
    // 处理 AxiosError
    handleAxiosError(error);
  } else {
    // 处理非 AxiosError
    handleGenericError(error);
  }
};

export interface ErrorResData {
  message: string;
  error: string;
}

export const handleAxiosError = (error: AxiosError<ErrorResData>) => {
  if (!error.response) {
    // 网络错误
    myalert("网络错误，请稍后重试");
  } else {
    // HTTP 状态码错误
    const status = error.response.status;
    switch (status) {
      case 400:
        if (error.response.data.message) {
          myalert(error.response.data.message);
        } else if (error.response.data.error) {
          myalert(error.response.data.error);
        } else {
          myalert("发生错误，请重试");
        }

        break;
      case 401:
        myalert("没有权限，请登录");
        // 可能需要重定向到登录页面
        window.location.href = "/login";
        break;
      case 403:
        myalert("拒绝操作，您没有权限");
        break;
      case 404:
        myalert("资源没找到");
        break;
      case 500:
        if (error.response.data.message) {
          myalert(error.response.data.message);
        } else {
          myalert("发生错误，请重试");
        }
        break;
      default:
        myalert(`错误: ${error.response.data.message || "位置错误"}`);
    }
  }
};

export const handleGenericError = (error: AxiosError<ErrorResData>) => {
  console.error("An unexpected error occurred:", error);
  alert("发生异常，请重试");
};

export function myalert(text: string) {
  Toast.show({
    content: text,
    position: "top",
    duration: 5000,
  });
}
