import TodoInfoBar from "../TodoInfoBar";
import { client } from "@/api/axios.ts";
import FinishIcon from "@/components/FinishIcon.tsx";
import { DEFAULT_reward_coins } from "@/config";
import { useAuth } from "@/hooks/useAuth";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { cacheAxios } from "@/utils/ErrorHandler";
import { Button, Dialog, Form, Stepper } from "antd-mobile";
import dayjs from "dayjs";

export default function Published({ data }: { data: ITaskDetail }) {
  const { state } = useAuth();
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "published",
  );
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return (
      <>
        <Form layout="horizontal">
          <Form.Header>
            <FinishIcon label={"已发布"} />
          </Form.Header>
          <Form.Item name="" label="创建人">
            <p>{data.users?.name}</p>
          </Form.Item>
          <Form.Item name="title" label="名称">
            <span>{data.title}</span>
          </Form.Item>
          <Form.Item name="describe" label="描述">
            <p>{data.describe}</p>
          </Form.Item>
          <Form.Item name="deadline" label="截止时间" trigger="onConfirm">
            <p>{dayjs(data.deadline).format("YYYY-MM-DD")}</p>
          </Form.Item>
        </Form>
        <Form layout="horizontal">
          <Form.Header>
            <FinishIcon label={"测试中心已接受"} />
          </Form.Header>
          <Form.Item name="describe" label="金币数量">
            <p>{data.reward_coins}</p>
          </Form.Item>
        </Form>
      </>
    );
  }

  if (state.user?.role !== "admin") {
    return (
      <>
        <Form layout="horizontal">
          <Form.Header>
            <FinishIcon label={"已发布"} />
          </Form.Header>
          <Form.Item name="" label="创建人">
            <p>{data.users?.name}</p>
          </Form.Item>
          <Form.Item name="title" label="名称">
            <span>{data.title}</span>
          </Form.Item>
          <Form.Item name="describe" label="描述">
            <p>{data.describe}</p>
          </Form.Item>
          <Form.Item name="deadline" label="截止时间" trigger="onConfirm">
            <p>{dayjs(data.deadline).format("YYYY-MM-DD")}</p>
          </Form.Item>
        </Form>
        <TodoInfoBar label="测试中心还未接受该任务" />
      </>
    );
  }

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton data={data} />}
      >
        <Form.Header>
          <FinishIcon label={"已发布"} />
        </Form.Header>
        <Form.Item name="" label="创建人" disabled>
          <p>{data.users?.name}</p>
        </Form.Item>
        <Form.Item name="" label="名称" disabled>
          <span>{data.title}</span>
        </Form.Item>
        <Form.Item name="" label="描述" disabled>
          <p>{data.describe}</p>
        </Form.Item>
        <Form.Item name="" label="截止时间" disabled>
          <p>{dayjs(data.deadline).format("YYYY-MM-DD")}</p>
        </Form.Item>
        <Form.Item
          name="reward_coins"
          label="奖励金币"
          initialValue={DEFAULT_reward_coins}
          rules={[{ required: true, message: "奖励金币不能为空" }]}
        >
          <Stepper min={0} />
        </Form.Item>
      </Form>
    </>
  );
}

function FinishButton({ data }: { data: ITaskDetail }) {
  if (data.assignee_user_id) {
    return null;
  }
  return (
    <Button
      block
      type="submit"
      color="primary"
      size="large"
      disabled={!!data.assignee_user_id}
    >
      接受测评任务
    </Button>
  );
}

function onFinish(data: ITaskDetail) {
  return function (values: ITaskDetail) {
    Dialog.show({
      content: (
        <div>
          <h1 className="mb-2 border-b pb-3 text-center text-xl">确认信息</h1>
          <div>
            <p>
              任务名称：<span>{data.title}</span>
            </p>
            <p>
              任务描述：<span>{data.describe}</span>
            </p>
            <p>
              截止时间：<span>{dayjs(data.deadline).format("YYYY-MM-DD")}</span>
            </p>
            <p>
              奖励金币：<span>{values.reward_coins}</span>
            </p>
          </div>
        </div>
      ),
      closeOnAction: true,
      actions: [
        [
          {
            key: "cancel",
            text: "取消",
          },
          {
            key: "delete",
            text: "接受",
            bold: true,
            onClick: () => {
              client
                .patch(`/tasks/${data.id}/assign`, values)
                .then((res) => {
                  apiResult(res);
                })
                .catch((err) => {
                  cacheAxios(err);
                });
            },
          },
        ],
      ],
    });
  };
}
