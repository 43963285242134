import { CheckCircleFill } from "antd-mobile-icons";

function FinishIcon({ label }: { label: string }) {
  return (
    <div className={"flex items-center"}>
      <CheckCircleFill fontSize={24} color="var(--adm-color-primary)" />
      <span
        className={"ml-2 h-4 text-[16px] leading-4"}
        style={{ color: "var(--adm-color-weak)" }}
      >
        {label}
      </span>
    </div>
  );
}

export default FinishIcon;
