import { client } from "@/api/axios.ts";
import { ITaskDetail } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import {
  Button,
  DatePicker,
  DatePickerRef,
  Dialog,
  Form,
  Input,
  TextArea,
} from "antd-mobile";
import dayjs from "dayjs";
import React, { RefObject } from "react";
import { useNavigate } from "react-router-dom";

const TaskAdd: React.FC = () => {
  const navigate = useNavigate();
  const onFinish = (values: ITaskDetail) => {
    Dialog.show({
      content: (
        <div>
          <h1>确认任务信息</h1>
          <pre>{JSON.stringify(values, null, 2)}</pre>
        </div>
      ),
      closeOnAction: true,
      actions: [
        [
          {
            key: "cancel",
            text: "取消",
          },
          {
            key: "delete",
            text: "发布",
            bold: true,
            onClick: () => {
              client.post("/tasks", values).then((res) => {
                console.log(res);
                apiResult(res, {
                  callback: () => {
                    navigate("/me");
                  },
                });
              });
            },
          },
        ],
      ],
    });
  };

  return (
    <Form
      layout="horizontal"
      onFinish={onFinish}
      footer={
        <Button block type="submit" color="primary" size="large">
          提交
        </Button>
      }
    >
      <Form.Header>创建评测任务</Form.Header>
      <Form.Item
        name="title"
        label="名称"
        rules={[{ required: true, message: "名称不能为空" }]}
      >
        <Input onChange={console.log} placeholder="请输入名称" />
      </Form.Item>
      <Form.Item name="describe" label="描述">
        <TextArea
          placeholder="请输入任务描述"
          maxLength={100}
          rows={2}
          showCount
        />
      </Form.Item>
      <Form.Item
        name="deadline"
        label="截止时间"
        trigger="onConfirm"
        onClick={(_e, datePickerRef: RefObject<DatePickerRef>) => {
          datePickerRef.current?.open();
        }}
      >
        <DatePicker min={new Date()}>
          {(value) =>
            value ? dayjs(value).format("YYYY-MM-DD") : "请选择日期"
          }
        </DatePicker>
      </Form.Item>
    </Form>
  );
};

export default TaskAdd;
