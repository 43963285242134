import { ITaskDetail } from "./task-detail";
import { client } from "@/api/axios";
import { myalert } from "@/utils/ErrorHandler";
import { RightOutlined } from "@ant-design/icons";
import { Input, List, Table, TableProps } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";

interface DataType {
  key: string;
  kuaidi: string;
  inner_code: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "连续录入快递单",
    dataIndex: "kuaidi",
    key: "kuaidi",
  },
  {
    title: "内部编号",
    dataIndex: "inner_code",
    key: "inner_code",
  },
];

export default function WebKuaidi() {
  const [data, setData] = useState<ITaskDetail[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [innerCode, setCode] = useState<string>("");
  const [items, setItems] = useState<DataType[]>([]);

  useEffect(() => {
    client.get("/tasks?status=kuaidi_number_ok,received").then((res) => {
      setData(res.data);
    });
  }, []);

  console.log(data);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyPress = (event: any) => {
    const kuaidi_number = inputValue.trim();
    if (event.key === "Enter" && kuaidi_number) {
      const task = data?.find((i) => i.kuaidi_number === kuaidi_number);
      if (!task) {
        myalert("快递单号不存在");
        return;
      }
      client.patch(`/tasks/${task.id}/received`).then((res) => {
        const code = task.inner_code;
        const nextState = produce(data, (draftState) => {
          const index = draftState.findIndex((i) => i.id === task.id);
          console.log(index, res.data);

          if (index === -1) return;
          draftState[index] = res.data;
        });
        setData(nextState);
        setCode(code);
        setItems([
          ...items,
          {
            key: `${items.length + 1}`,
            kuaidi: inputValue.trim(),
            inner_code: code,
          },
        ]);
        setInputValue("");
      });
    }
  };

  return (
    <div className="web-kuaidi container mx-auto">
      <div className="flex">
        <div className="grow">
          <List
            header={
              <div className="font-bold">测评任务列表(已上传快递单号)</div>
            }
            // footer={<div>Footer</div>}
            bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <div>
                    <span
                      className={`border p-2 font-bold ${item.status === "received" ? "bg-green-500" : "bg-red-500"}`}
                    >
                      {item.kuaidi_number}
                    </span>
                    <span
                      className={`border p-2 font-bold ${item.status === "received" ? "bg-green-500" : "bg-red-500"}`}
                    >
                      {item.status === "received" ? "快递已收到" : "快递未收到"}
                    </span>
                    <span className="border p-2 font-bold">
                      {item.inner_code}
                    </span>
                  </div>,
                ]}
              >
                {item.title}
              </List.Item>
            )}
          />
        </div>
        <div className="ml-5 w-[420px] shrink-0 rounded-md border p-4">
          <div className="mb-2 border-b pb-2">
            <h1 className="mb-2 font-bold">扫码录入快递单号</h1>
            <h2 className="">鼠标选中输入框，用扫码枪录入，回车输入</h2>
          </div>

          <div className="mb-4 flex items-end gap-2">
            <div>
              <Input
                allowClear
                value={inputValue}
                placeholder="鼠标选中这里"
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="flex h-[30px] items-center">
              <RightOutlined />
              <span>转码</span>
              <RightOutlined />
            </div>

            <div className="rounded-lg border p-3 text-3xl">{innerCode}</div>
          </div>
          <div className="border">
            <Table
              size="small"
              columns={columns}
              showHeader={false}
              pagination={false}
              dataSource={items}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
