import { client } from "@/api/axios.ts";
import { UI_URL } from "@/config";
import { useAuth } from "@/hooks/useAuth.tsx";
import { LOGIN, LOGOUT } from "@/provider/AuthProvider";
import { Button, List } from "antd-mobile";
import {
  PayCircleOutline,
  SetOutline,
  UnorderedListOutline,
  AddCircleOutline,
} from "antd-mobile-icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Me() {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const userInfo = state.user;
  console.log(state);

  useEffect(() => {
    if (state.isAuthenticated) {
      return;
    }
    auth(() => {
      dispatch({ type: LOGIN });
    });
  }, [state]);

  if (!state.isAuthenticated) {
    return (
      <div className={"flex h-screen flex-col items-center justify-start px-4"}>
        <h1 className={"mb-96 mt-10 text-4xl"}>欢迎来到评测中心</h1>
        <Button block color="primary" size="large" onClick={login}>
          登录
        </Button>
      </div>
    );
  }
  return (
    <div className={"flex h-full flex-col bg-gray-100 pb-10"}>
      <div
        className={"mb-4 border-b bg-white pb-6 pt-10 text-center font-bold"}
      >
        <h1 className={"text-4xl"}>欢迎来到评测中心</h1>
        <p className={"mt-8 text-xl"}>用户名：{userInfo.name}</p>
        <p className={"mt-4 text-xl"}>
          身份：{userInfo.role === "admin" ? "管理员" : "志愿者"}
        </p>
      </div>
      <List className="mb-4">
        <List.Item
          description="实名认证并注册"
          clickable={!state.user.isIdAuth}
          onClick={() => {
            if (state.user.isIdAuth) {
              return;
            }
            navigate("/register");
          }}
          extra={state.user.isIdAuth ? "已注册" : "未注册"}
        >
          招募志愿者
        </List.Item>
      </List>
      <List>
        <List.Item
          prefix={<AddCircleOutline />}
          disabled={!state.user.isIdAuth}
          onClick={() => {
            navigate("/add-task");
          }}
        >
          我要测评
        </List.Item>
        <List.Item
          prefix={<UnorderedListOutline />}
          extra="5"
          onClick={() => {
            navigate("/my-tasks");
          }}
        >
          测评记录
        </List.Item>
        <List.Item prefix={<PayCircleOutline />} extra="10" onClick={() => {}}>
          总金币
        </List.Item>
        <List.Item prefix={<SetOutline />} onClick={() => {}}>
          设置
        </List.Item>
      </List>
      <div className={"mt-auto flex items-center justify-center px-4"}>
        <Button
          block
          color="primary"
          size="large"
          fill="outline"
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            dispatch({ type: LOGOUT });
            window.location.href = "/me";
          }}
        >
          退出
        </Button>
      </div>
    </div>
  );
}

function login() {
  client
    .post("/wx/login", {
      // redirectUri: encodeURIComponent("http://192.168.31.234:5173/"),
      redirectUri: encodeURIComponent(UI_URL),
      // redirectUri: encodeURIComponent("https://test-center.c4m.icu/"),
    })
    .then((res) => {
      console.log("/wx/login", res.data);
      window.location.href = res.data.auth_url;
    });
}

function auth(cb: () => void) {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (!code) {
    return;
  }
  const codeRecord = JSON.parse(localStorage.getItem("codeRecord") || "{}");
  if (!codeRecord[code] || Date.now() - codeRecord[code] > 1000) {
    localStorage.setItem(
      "codeRecord",
      JSON.stringify({
        [code]: Date.now(),
      }),
    );
    client
      .post("/wx/auth", { code })
      .then((data) => {
        console.log("/wx/auth", data);
        if (data.status === 200) {
          // 登录或注册成功后的处理
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("refreshToken", data.data.refreshToken);
          console.log("Logged in successfully");
          cb();
        } else {
          // 处理错误
          console.error("Error:", data.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
