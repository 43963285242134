import WechatPayButton from "../WechatPayButton";
import { Upload, uploadImage } from "./received";
import { client } from "@/api/axios";
import FinishInfoBar from "@/components/FinishInfoBar.tsx";
import TodoIcon from "@/components/TodoIcon.tsx";
import {
  IReturnInfo,
  ITaskDetail,
  statusOrder,
} from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import {
  Button,
  Form,
  ImageUploader,
  ImageUploadItem,
  Input,
} from "antd-mobile";

// 已上传退货单号，状态已寄出shipped
export default function Shipped({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex((status) => status === "shipped");
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return <FinishInfoBar label={"运费已退还"} />;
  }

  const isUploaded = data.task_media.some(
    (media) => media.for_what === "return_fee" && media.file_type === "image",
  );

  if (isUploaded) {
    return (
      <>
        <Form layout="horizontal" footer={<WechatPayButton taskId={data.id} />}>
          <Form.Header>
            <TodoIcon label={"退还运费"} />
          </Form.Header>
          <Form.Item
            name="return_fee"
            label="快递费金额"
            rules={[{ required: true, message: "快递费金额" }]}
          >
            <ImageUploader
              value={data.task_media
                .filter(
                  (i) => i.file_type !== "video" && i.for_what === "return_fee",
                )
                .map(uploadImage)}
              disableUpload={true}
              deletable={false}
              showUpload={false}
              upload={function (file: File): Promise<ImageUploadItem> {
                console.log(file);
                return Promise.resolve({} as ImageUploadItem);
              }}
            />
          </Form.Item>
          <Form.Item name="return_fee" label="金额" extra="元">
            <p>
              <span>{data.return_fee}</span>
            </p>
          </Form.Item>
        </Form>
      </>
    );
  }

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={
          <Button block type="submit" color="primary" size="large">
            提交金额
          </Button>
        }
      >
        <Form.Header>
          <TodoIcon label={"上传运费金额"} />
        </Form.Header>
        <Form.Item
          name=""
          label="快递费金额"
          rules={[{ required: true, message: "快递费金额" }]}
        >
          <Upload
            taskId={data.id}
            task_media={data.task_media}
            forWhat={"return_fee"}
            callback={(src) => {
              console.log(src);
            }}
          />
        </Form.Item>
        <Form.Item
          name="return_fee"
          label="快递费金额"
          rules={[{ required: true, message: "快递费金额" }]}
        >
          <Input type={"number"} placeholder="请输入快递费金额" />
        </Form.Item>
      </Form>
    </>
  );
}

function onFinish(data: ITaskDetail) {
  return function (values: IReturnInfo) {
    // 上传返回退货，状态将变为已上传
    client.patch(`/tasks/${data.id}/return_fee`, values).then((res) => {
      apiResult(res);
    });
  };
}
