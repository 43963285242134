import { useEffect } from "react";

export default function XieYi() {
  useEffect(() => {
    const content = document.getElementById("content");
    const text = content.textContent.trim();
    const paragraphs = text.split(/\n/); // 假设段落之间有空行
    content.innerHTML = ""; // 清空内容
    paragraphs.forEach((paragraph) => {
      const p = document.createElement("p");
      p.textContent = paragraph.trim();
      content.appendChild(p);
    });
  }, []);
  return (
    <div className="xieyi p-3">
      <article id="content">
        {`特别提示：
欢迎您来到消权测评小程序！
 为正确使用我们的服务，并规范双方的权利义务，您应当阅读并遵守《服务协议》（以下简称“本协议” ）。请您下单之前仔细阅读、 充分理解本协议的各个条款， 特别是以加粗或下划线形式提示您注意的条款。
   当您按照页面提示下单时即视为您已充分阅读、理解并自愿受本协议的约束。 您在阅读本协议过程中，如果您不同意或不理解本协议或其中任何条款， 您应立即停止使用或接受服务。
   本“特别提示” 是本协议正文的组成部分。 
一、签约主体
本协议的签约主体甲方为曲阜市消权市场调查有限公司（以下简称：消权测评），乙方为（系）您注册的账号实名认证主体（以下简称：您）。
二、测评行为/活动规范
2.1测评确认。当您在小程序注册时，请您务必仔细确认所小程序使用规则、使用限制等重要事项，并在注册时核实您的联系地址、电话、收款方式等信息。 如您填写的实名人非您本人，则该实名人的行为和意思表示产生的法律后果均由您（和实名人）承担。 
2.2测评秩序。消权测评是基于广大消费者的消费需求而产生的社会公益性测评，对促进消费者权益保护、提供更多“避坑指南”和市场消费环境数据、倒逼商家完善产品和服务质量做出社会贡献，因需要通过大量的商品订单进行测评并实现大量测评数据，您作为测评消费志愿者参加测评活动，应当了解此次活动为具有公益性的活动，对于您的志愿测评，消权测评将根据您的测评贡献奖励金币。当您在购物平台或门店购买的商品，您可将商品收货地址和收件人电话填写消权测评的测评员，所下单的商品所有权人为您，消权测评仅对商品做出测评，不对货物的损毁、丢失等承担责任。到货测评的商品运费由您或您需要测评的商家承担，对于消权测评需要承担的到货运费或运费到付等测评件，消权测评将全部拒收处理，对此造成的损失消权测评不予承担。在测评完成后，消权测评将根据您提供的退货地址和收件人退回，如您是退还给商家，消权测评可先行为您垫付退货运费，待购物平台或商家给您支付运费后，三日内您向消权测评支付运费，运费金额按照购物平台或商家给您退还的费用。如您是需要退还给本人，消权测评为您先行垫付运费，待您收到货后应向消权测评支付运费，运费金额按照消权测评实际支付运费的金额进行支付。测评行为应当基于真实的消费需求，且不得实施恶意购买、恶意维权、虚假交易等扰乱小程序服务平台正常交易秩序的行为。
2.3 合同成立与生效。您通过完成注册实名后，您与消权测评之间的服务合同才成立并生效。 
三、用户承诺和保证
3.1 总体原则：您同意严格遵守法律法规的规定，以及本协议之约定，遵循诚实信用原则，不从事任何违反国家法律法规、违反本协议，或从事任何扰乱综合小程序秩序，侵害他人利益、小程序利益、公序良俗及社会公共利益的行为。 
  3.2 秩序保护。 您不得从事扰乱综合小程序秩序的行为， 包括但不限于：
1) 通过任何不正当手段达成交易或参与活动，如使用插件、外挂等工具；
2) 从事虚假交易；
3) 提供虚假信息；
4) 当事人的隐私保护，不得采取对当事人的攻击；
5) 从事赌博、洗钱、违规套现、刷信誉等行为；
6) 利用技术漏洞或规则漏洞下单、获取福利、补贴；
7) 恶意退款；
8) 涉及消权测评的保密内容不得对外泄露；
9) 从事其他扰乱综合平台交易及管理秩序的行为。 
  3.3 权益保护。 您不得从事有损于甲方或任何第三方合法权益的行为，包括但不限于：
1) 假冒消权测评发布任何诈骗或虚假信息；
2) 利用小程序传播恶意程序或计算机病毒；
3) 通过测评对他人进行敲诈勒索；
4) 恶意对商品进行负面评价；
5) 发布违规信息、垃圾信息的；
6) 恶意索赔、投诉的；
7) 协助他人获取不正当利益，或教唆他人从事本协议所禁止的行为；
8) 从事其他有损于甲方或任何第三方合法权益的行为。
  3.4 发布要求。您不得通过小程序综合服务平台发表、转发或者传播含有下列内容的信息，包括但不限于：
1) 反对宪法所确定的基本原则的；
2) 危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；
3) 损害国家荣誉和利益的；
4) 煽动民族仇恨、民族歧视，破坏民族团结的；
5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
8) 侮辱或者诽谤他人，侵害他人合法权益的；
9) 含有法律、行政法规及本协议禁止的其他内容的。 
四、双方义务。
4.1 在使用过程中，小程序（甲方）将基于综合平台管理需要，依据相关法律法规及本协议的约定，履行对您作出的承诺或赔付义务。包括：
1)提供完整的开箱测评过程；
2)根据您的测评贡献发放金币奖励；
3) 对在服务中知悉的乙方保密信息、商业秘密、个人秘密等保密
4.2 消权测评仅以普通消费者的角度对商品进行客观公正的测评，消权测评并非司法机关，对所作出的评价仅根据普通消费者的感官角度进行评价，对于测评的商品存在的问题如您需要维权建议您通过第三方检测机构作出进一步检测，消权测评对此作出的测评结果不具有任何法律效力。如因此产生的法律责任由您承担。
4.3 消权测评流程：
1）进行全程开箱视频取证；
2）对商品进行感官检查；
3）对商品存在的问题从消费者角度出发给出评价。
4.4 关于运费支付的约定：
通过第2.2条中的方式，在您收到购物平台或商家支付的运费后，您应在3日内向消权测评支付垫付的运费，如3日内您未向消权测评支付，则视为您违约，违约方承担守约方所有维权费用（包括但不限于法律咨询费、律师费、调档费、诉讼费、材料打印费、差旅费、住宿费等实现维权的一切费用）。
五、违约条款
  任何一方的违约行为致使守约方利益受损的，应赔偿守约方的损失。上述损失包括但不限于直接经济损失、为减少损失、防止损失扩大、固定证据或者追究相关法律责任所支出的诉讼费、保全费、 保全保险费、 公证费、律师费、差旅费、鉴定费、调档费等一切有关费用和支出。 
 六、协议的终止。
6.1您注销账号实名，视为本协议终止
6.2出现以下情况时，甲方有权单方解除本协议：
1) 您违反本协议的任一约定的；
2) 您与小程序产生纠纷的；
3) 您的行为已经或可能损害甲方商誉的；
4) 出于配合司法或行政机关的需要；
5) 甲方自主决定解除本协议，并提前通知您；
6) 出现其它应当终止服务的情况 
6.3后续事项。
协议终止或解除后续事项的处理：
1) 自本协议终止或解除之日起，甲方将关闭您的账户权限。
2) 本协议终止或解除后，甲方有权保留您的注册信息及交易行为记录等数据，但甲方不承担在协议终止或解除后向您或第三方提供任何数据信息的义务，也不就协议终止或解除向您或任何第三方承担责任， 法律法规另有规定的除外。
3) 本协议终止或解除后， 甲方仍保留对您过往的违约行为追究责任的权利 
七、其他约定
7.1 乙方同意：若乙方无特别提前向甲方说明的，默认甲方可在公众平台分享发布测评结果及数据。
八、法律适用及管辖
8.1 法律适用。本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
8.2约定管辖。对于因本协议而产生的或者与本协议有关的争议，双方均应努力通过友好协商的方式进行解决。协商不成的，任何一方均有权向济宁仲裁委员会提起仲裁申请。

`}
      </article>
    </div>
  );
}
