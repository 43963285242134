import { client } from "@/api/axios.ts";
import FinishIcon from "@/components/FinishIcon.tsx";
import TodoIcon from "@/components/TodoIcon.tsx";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { Button, Form, Input } from "antd-mobile";

// 志愿者已上传退货信息，等待进行退货，等待上传退货单号
export default function Returned({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "returned",
  );
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return (
      <>
        <Form layout="horizontal">
          <Form.Header>
            <FinishIcon label={"退货已寄出，单号已上传"} />
          </Form.Header>
          <Form.Item
            name="return_code"
            label="退货单号"
            extra={
              <div className={"border-l border-gray-600 pl-3"}>
                <a style={{ color: "var(--adm-color-primary)" }}>复制</a>
              </div>
            }
          >
            <p>{data.return_code}</p>
          </Form.Item>
        </Form>
      </>
    );
  }

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton />}
      >
        <Form.Header>
          <TodoIcon label={"等待测评中心上传退货单号"} />
        </Form.Header>
        <Form.Item
          name="return_code"
          label="退货单号"
          rules={[{ required: true, message: "退货单号" }]}
        >
          <Input placeholder="请输入退货单号" />
        </Form.Item>
      </Form>
    </>
  );
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      提交
    </Button>
  );
}

// 下一步
function onFinish(data: ITaskDetail) {
  return function (values: { return_code: string; return_fee: string }) {
    client
      .patch(`/tasks/${data.id}/shipped`, {
        return_code: values.return_code,
        return_fee: values.return_fee,
      })
      .then((res) => {
        apiResult(res);
      });
  };
}
