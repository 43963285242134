import { fetcher } from "@/api/axios.ts";
import Error from "@/components/Error.tsx";
import Spinner from "@/components/Spinner.tsx";
import { useAuth } from "@/hooks/useAuth";
import { ITaskDetail, statusMap } from "@/router/task-detail.tsx";
import { List } from "antd-mobile";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const TasksPage: React.FC = () => {
  const { state } = useAuth();
  const isUser = state?.user?.role !== "admin";
  const url = isUser ? `/tasks/user/${state.user.id}` : "/tasks";
  const { data, error, isLoading } = useSWR<ITaskDetail[]>(url, fetcher, {
    revalidateOnFocus: true,
    revalidateOnMount: true,
    refreshInterval: 0, // 不刷新
  });
  const navigate = useNavigate();

  if (isLoading) return <Spinner />;
  if (error) return <Error />;
  console.log(data);

  if (data?.length === 0) {
    return (
      <List header="任务列表">
        <List.Item>暂无任务</List.Item>
      </List>
    );
  }
  return (
    <List header="任务列表">
      {data?.map((task) => (
        <List.Item
          key={task.id}
          // prefix={
          //   <Image
          //     src={task.avatar || ""}
          //     style={{ borderRadius: 4 }}
          //     fit="cover"
          //     width={40}
          //     height={40}
          //   />
          // }
          description={task.describe}
          extra={statusMap[task.status]?.title}
          onClick={() => {
            navigate(`/tasks/${task.id}`);
          }}
        >
          {task.title}
        </List.Item>
      ))}
    </List>
  );
};

export default TasksPage;
