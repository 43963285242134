import "./App.css";
import { AuthProvider } from "./provider/AuthProvider";
import Home from "./router/home";
import Register from "./router/register";
import Web from "./router/web";
import WebDaDan from "./router/web-dadan";
import WebKuaidi from "./router/web-kuaidi";
import WebLogin from "./router/web-login";
import XieYi from "./router/xieyi";
import ErrorPage from "@/router/error-page.tsx";
import Me from "@/router/me.tsx";
import Root from "@/router/root.tsx";
import TaskAdd from "@/router/task-add.tsx";
import TaskDetail from "@/router/task-detail.tsx";
import TasksPage from "@/router/tasks.tsx";
import UsersPage from "@/router/users.tsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    Component: Root,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        Component: Me,
      },
      {
        path: "/home",
        Component: Home,
      },
      {
        path: "/me",
        Component: Me,
      },
      {
        path: "users",
        Component: UsersPage,
      },
      {
        path: "tasks",
        Component: TasksPage,
      },
    ],
  },
  {
    path: "my-tasks",
    element: <TasksPage />,
  },
  {
    path: "register",
    Component: Register,
  },
  {
    path: "add-task",
    Component: TaskAdd,
  },
  {
    path: "tasks/:taskId",
    Component: TaskDetail,
  },
  {
    path: "/web-login",
    Component: WebLogin,
  },
  {
    path: "/web",
    Component: Web,
    children: [
      {
        index: true,
        Component: WebKuaidi,
      },
      {
        path: "/web/kuaidi",
        Component: WebKuaidi,
      },
      {
        path: "/web/dadan",
        Component: WebDaDan,
      },
    ],
  },
  {
    path: "/xieyi",
    Component: XieYi,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
