import { useAuth } from "@/hooks/useAuth";
import { TabBar } from "antd-mobile";
import { UnorderedListOutline, UserOutline } from "antd-mobile-icons";
import React, { FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Bottom: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { state } = useAuth();
  const user = state.user;

  const prefix = user.role === "admin" ? "(管理员)" : "";

  const setRouteActive = (value: string) => {
    navigate(value);
  };

  const tabs = [
    // {
    //   key: "/home",
    //   title: "首页",
    //   icon: <AppOutline />,
    // },

    {
      key: "/me",
      title: "我" + prefix,
      icon: <UserOutline />,
    },
  ];

  if (user.role === "admin") {
    tabs.unshift({
      key: "/tasks",
      title: "测评任务",
      icon: <UnorderedListOutline />,
    });
  }

  console.log(pathname);

  return (
    <TabBar
      activeKey={pathname === "/" ? "/me" : pathname}
      onChange={(value) => setRouteActive(value)}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};

const Root: React.FC = () => {
  return (
    <div className={"flex h-screen flex-col"}>
      <div
        className={
          "h-full w-full flex-1 overflow-y-auto overflow-x-hidden border-t"
        }
      >
        <Outlet />
      </div>
      <div className={"flex-shrink-0 flex-grow-0 border-t"}>
        <Bottom />
      </div>
    </div>
  );
};

export default Root;
