import { IUser } from "@/router/task-detail.tsx";
import { jwtDecode } from "jwt-decode";

function getJwtPayload(token: string) {
  try {
    const payload = jwtDecode(token) as IPayload;
    console.log("JWT payload:", payload);
    return payload;
  } catch (error) {
    console.error("Failed to decode JWT", error);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/me";
    return null;
  }
}

export default function getUser(): IUser {
  // 获取存储在本地的 JWT 并解码
  const token = localStorage.getItem("accessToken");
  console.log("accessToken:", token);
  if (!token) {
    return {} as IUser;
  }
  const payload = getJwtPayload(token);
  if (!payload) {
    return {} as IUser;
  }
  console.log("Username:", payload.username); // 假设 payload 中有 username 字段

  return payload;
}
