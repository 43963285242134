import { fetcher } from "@/api/axios.ts";
import Error from "@/components/Error.tsx";
import Spinner from "@/components/Spinner.tsx";
import Assigned from "@/components/status/assigned.tsx";
import Evaluated from "@/components/status/evaluated.tsx";
import Kuaidi from "@/components/status/kuaidi-number";
import Published from "@/components/status/published";
import Received from "@/components/status/received.tsx";
import Returned from "@/components/status/returned.tsx";
import ReturnedFreight from "@/components/status/returned_freight.tsx";
import Shipped from "@/components/status/shipped.tsx";
import { Steps } from "antd-mobile";
import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export interface IUser {
  id: number;
  name: string;
  phone?: string;
  role: "admin" | "customer";
  isIdAuth: boolean;
}
export interface IReturnInfo {
  id: number;
  name: string;
  phone: string;
  address: string;
}
export interface ITaskDetail {
  inner_code: string;
  kuaidi_number: string;
  id: number;
  assignee_user_id: number;
  status: string;
  title: string;
  describe: string;
  created_time: number;
  deadline: number;
  update_time: number;
  users: IUser;
  return_info: IReturnInfo;
  test_results: string[];
  return_code: string;
  return_fee: number;
  reward_coins: number;
  task_media: {
    for_what: string;
    file_url: string;
    id: number;
    file_type: "image" | "video";
  }[];
}

const { Step } = Steps;
interface IStatusMap {
  [key: string]: { title: string; description: string };
}
export const statusMap: IStatusMap = {
  published: {
    title: "已发布",
    description: "等待认领",
  },
  assigned: {
    title: "已被认领",
    description: "提供商品信息、收件地址",
  },
  Kuaidi: {
    title: "已上传快递单号",
    description: "消费者提供快递单号",
  },
  kuaidi_number_ok: {
    title: "等待接收快递",
    description: "等待快递入库",
  },
  received: {
    title: "快递已送达",
    description: "等待测评",
  },
  evaluated: {
    title: "已完成测评",
    description: "已上传结果，等待用户退货",
  },
  returned: {
    title: "已收到退货信息",
    description: "等待寄出",
  },
  shipped: {
    title: "已寄出",
    description: "等待运费退还",
  },
  returned_freight: {
    title: "已运费退还",
    description: "结束",
  },
  finished: {
    title: "结束",
    description: "任务已结束",
  },
};

// 已被认领,已收到邮件,已测评完成,已收到退货信息,已寄出,已运费退还,结束
export const statusOrder = [
  "published",
  "assigned",
  "kuaidi_number_ok",
  "received",
  "evaluated",
  "returned",
  "shipped",
  "returned_freight",
  "finished",
];

const TaskDetail: React.FC = () => {
  const params = useParams();
  const { data, error, isLoading } = useSWR(
    `/tasks/${params.taskId}`,
    fetcher,
    {
      // revalidateOnFocus: true,
      // revalidateOnMount: true,
      refreshInterval: 0, // 不刷新
    },
  );

  if (isLoading || !data) return <Spinner />;
  if (error) return <Error />;
  console.log(data);

  return (
    <div>
      <Published data={data} />
      <Assigned data={data} />
      <Kuaidi data={data} />
      <Received data={data} />
      <Evaluated data={data} />
      <Returned data={data} />
      <Shipped data={data} />
      <ReturnedFreight data={data} />
      {/* <TaskStep statusIndex={statusIndex} /> */}
    </div>
  );
};

export default TaskDetail;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TaskStep(props: { data: ITaskDetail }) {
  const statusIndex = statusOrder.indexOf(props.data.status);
  return (
    <>
      <p>任务状态</p>
      <div>
        <Steps direction="vertical" current={statusIndex}>
          {statusOrder.map((status) => {
            return (
              <Step
                key={status}
                title={statusMap[status].title}
                description={statusMap[status].description}
              />
            );
          })}
        </Steps>
      </div>
    </>
  );
}
