import TodoInfoBar from "../TodoInfoBar";
import { client } from "@/api/axios.ts";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { Button, Form } from "antd-mobile";

// 志愿者上传快递单号
export default function Kuaidi({ data }: { data: ITaskDetail }) {
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "kuaidi_number_ok",
  );
  if (statusIndex < receivedIndex) return null;
  // 已完成当前状态
  if (statusIndex > receivedIndex) return null;
  // 当前状态
  return (
    <>
      <TodoInfoBar label="等待接收快递" />
      <Form
        layout="horizontal"
        onFinish={onFinish(data)}
        footer={<FinishButton />}
      ></Form>
    </>
  );
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      商品快递已送达
    </Button>
  );
}

function onFinish(data: ITaskDetail) {
  return function () {
    client.patch(`/tasks/${data.id}/received`).then((res) => {
      apiResult(res);
    });
  };
}
