import { client } from "@/api/axios.ts";
import { Button } from "antd-mobile";

export default function UsersPage() {
  client.get("users").then((res) => {
    console.log(res);
  });
  return (
    <div>
      <Button
        onClick={() => {
          client.post("users", {}).then((res) => {
            console.log(res);
          });
        }}
      >
        post
      </Button>
      <Button
        onClick={() => {
          client.post("tasks", {}).then((res) => {
            console.log(res);
          });
        }}
      >
        post tasks
      </Button>
    </div>
  );
}
