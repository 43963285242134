import TodoInfoBar from "../TodoInfoBar";
import { client } from "@/api/axios.ts";
import FinishInfoBar from "@/components/FinishInfoBar.tsx";
import TodoIcon from "@/components/TodoIcon.tsx";
import { API_URL } from "@/config";
import getUser from "@/hooks/getUser.ts";
import { ITaskDetail, statusOrder } from "@/router/task-detail.tsx";
import { apiResult } from "@/utils/ApiResult";
import { myalert } from "@/utils/ErrorHandler";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, ImageUploader, Space } from "antd-mobile";
import { ImageUploadItem } from "antd-mobile/es/components/image-uploader";
import { useState } from "react";

const items = [
  "商品无合格证",
  "商品无商标，伪劣",
  "商品质量太差",
  "商品手感与描述不符",
  "商品虚假宣传与描述不符",
  "商品材质缺陷",
  "商品尺寸不一致",
  "商品无使用说明书",
  "商品无产品信息",
  "商品无生产信息",
  "商品数量不一致",
  "商品有瑕疵",
  "商品不新，类似二手货",
  "商品性能有问题",
  "商品做工不细致",
  "商品做工粗糙",
  "商品实物与宣传偏差",
  "商品缺斤少两",
  "商品无法使用",
  "商品货不对板",
  "商品有色差",
  "商品有污渍",
  "商品有损坏",
  "商品有变形",
  "商品有少件",
];

// 商品快递已送达，等待测评完成，上传测评结果
export default function Received({ data }: { data: ITaskDetail }) {
  const [videos, setVideos] = useState<string[]>([]);
  const user = getUser();
  const statusIndex = statusOrder.findIndex((status) => status === data.status);
  const receivedIndex = statusOrder.findIndex(
    (status) => status === "received",
  );
  if (statusIndex < receivedIndex) return null;
  if (statusIndex > receivedIndex) {
    return (
      <>
        <FinishInfoBar label={"商品快递已送达"} />
      </>
    );
  }

  console.log(data);

  return (
    <>
      <FinishInfoBar label={"商品快递已送达"} />
      {user.role === "customer" && (
        <TodoInfoBar label={"等待测评完成上传测评结果"} />
      )}
      {user.role === "admin" && (
        <Form
          layout="horizontal"
          onFinish={onFinish(data)}
          footer={<FinishButton />}
        >
          <Form.Header>
            <TodoIcon label={"等待测评完成，上传测评结果"} />
          </Form.Header>
          <Form.Item name="" label="测评结果(图片)">
            <Upload
              taskId={data.id}
              task_media={data.task_media}
              forWhat="received"
            />
          </Form.Item>
          <Form.Item name="" label="测评结果(视频)" extra={""}>
            <div className="flex flex-col pr-4">
              {videos.length > 0 &&
                videos.map((i, index) => (
                  <video
                    key={index}
                    src={i}
                    controls
                    className="w-full"
                  ></video>
                ))}
              {data.task_media
                .filter((i) => i.file_type === "video")
                .map((i) => ({ url: API_URL + "/" + i.file_url }))
                .map((i, index) => (
                  <video
                    src={i.url}
                    key={index}
                    controls
                    className="mb-1 w-full"
                  ></video>
                ))}
              <div className="plus-button">
                <PlusOutlined
                  style={{ fontSize: 32, color: "var(--adm-color-weak)" }}
                />
                <input
                  type="file"
                  accept="video/*"
                  className="w-[246px]"
                  capture="environment"
                  onChange={async (e) => {
                    console.log(e.target.files);
                    const file = e.target.files?.[0];
                    if (!file) return;
                    upload(file, data.id, "received", (url) => {
                      setVideos((prev) => [...prev, url]);
                    });
                  }}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="test_results"
            label="测评结论"
            rules={[
              {
                required: true,
                message: "测评结论至少选择3个",
                type: "array",
                min: 3,
              },
            ]}
          >
            <Checkbox.Group>
              <Space direction="vertical">
                {items.map((i) => (
                  <Checkbox key={i} value={i}>
                    {i}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export function Upload({
  task_media,
  callback,
  taskId,
  forWhat,
}: {
  callback?: (src: string) => void;
  taskId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  task_media: ITaskDetail["task_media"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forWhat: string;
}) {
  const [fileList, setFileList] = useState<ImageUploadItem[]>(
    task_media
      .filter((i) => i.file_type === "image" && i.for_what === forWhat)
      .map((i) => ({ url: API_URL + "/" + i.file_url })),
  );

  return (
    <ImageUploader
      value={fileList}
      onChange={setFileList}
      // upload={mockUpload}
      upload={async (file) => {
        console.log(file);
        console.log(task_media);
        return upload(file, taskId, forWhat, callback);
      }}
    />
  );
}

const CHUNK_SIZE = 1 * 1024 * 1024; // 5MB
async function upload(
  file: File,
  taskId: number,
  forWhat: string,
  callback?: (src: string) => void,
) {
  const fileType = file.type.startsWith("image/") ? "image" : "video";
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  let uploadedChunks = 0;

  for (let start = 0; start < file.size; start += CHUNK_SIZE) {
    const chunk = file.slice(start, start + CHUNK_SIZE);
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("fileName", encodeURIComponent(file.name));
    formData.append("chunkIndex", Math.floor(start / CHUNK_SIZE) + "");
    formData.append("totalChunks", totalChunks + "");

    try {
      await client.post(`/api/upload/${taskId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      uploadedChunks++;
      console.log(
        start,
        `Progress: ${Math.round((uploadedChunks / totalChunks) * 100)}%`,
      );
    } catch (error) {
      // TODO 清除分片文件
      console.error(1, error);
    }
  }

  try {
    const response = await client.post(`/api/merge/${taskId}`, {
      fileName: encodeURIComponent(file.name),
      totalChunks: totalChunks,
      fileType,
      forWhat,
    });
    callback && callback(response.data.file_url as string);
    return uploadImage(response.data) as ImageUploadItem;
  } catch (error) {
    console.error("File upload failed", error);
    return {
      url: URL.createObjectURL(file),
    } as ImageUploadItem;
  }
}

export function uploadImage(media: { id: number; file_url: string }) {
  return {
    url: API_URL + "/" + media.file_url,
  };
}

function FinishButton() {
  return (
    <Button block type="submit" color="primary" size="large">
      上传测评结果
    </Button>
  );
}

function onFinish(data: ITaskDetail) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (values: any) {
    console.log(values);

    client.patch(`/tasks/${data.id}/evaluated`, values).then((res) => {
      apiResult(res);
    });
  };
}
