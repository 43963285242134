import { client } from "@/api/axios";
import { myalert } from "@/utils/ErrorHandler";
import { Button, Checkbox, Form, Input } from "antd-mobile";
import { divide } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";

interface InputProps {
  idCard: string;
  name: string;
  phone: string;
}

export default function Register() {
  const [agree, setAgree] = useState<boolean>(false);
  const [form] = Form.useForm();
  const onFinish = (values: InputProps) => {
    client
      .post("/validateIdCard", {
        idCard: values.idCard,
        name: values.name,
      })
      .then(() => {
        myalert("实名认证成功，注册成功");
      })
      .catch((err) => {
        myalert(err.error);
      });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        footer={
          <div>
            <div className="mb-4 flex items-center gap-2">
              <Checkbox
                onChange={() => {
                  setAgree(!agree);
                }}
              >
                同意
              </Checkbox>
              <Link to={"/xieyi"}>
                <span className="text-[16px] text-blue-500 underline underline-offset-2 transition duration-300 ease-in-out hover:text-blue-700 hover:underline">
                  用户协议
                </span>
              </Link>
            </div>
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              disabled={!agree}
            >
              实名认证并注册
            </Button>
          </div>
        }
      >
        <Form.Header>实名认证并注册</Form.Header>
        <Form.Item
          name="name"
          label="姓名"
          rules={[{ required: true, message: "姓名不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          name="idCard"
          label="身份证号"
          rules={[{ required: true, message: "身份证号不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入身份证号" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="手机号"
          rules={[{ required: true, message: "手机号不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入手机号" />
        </Form.Item>
      </Form>
    </div>
  );
}
